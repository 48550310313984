import { FunctionComponent } from "react"
import './Spinner.scss'

export declare type SpinnerProps = {
    color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark',
    type?: 'grow' | 'border',
    size?: 'sm',
    rem?: number,
    weight?: '',
    className?: string,
    hidden?: boolean,
};

export const Spinner: FunctionComponent<SpinnerProps> = ({ color, type, size, rem, className, hidden }) => {
    return (
        <span
            hidden={hidden}
            className={`spinner-${type ?? 'border'} ${size ? `spinner-${type ?? 'border'}-${size}` : ''} text-${color ?? 'primary'} m-auto ${className}`}
            style={{ width: `${rem ?? 2}rem`, height: `${rem ?? 2}rem` }}>
            <span className="visually-hidden">Loading...</span>
        </span>
    )
}

export const FullSpinner: FunctionComponent<SpinnerProps> = ({ color, type, size, rem }) => {
    return (
        <div className="fs-spinner">
            <div className="top-50 start-50 position-absolute">
                <Spinner color={color} type={type} size={size} rem={rem} />
            </div>
        </div>
    )
}