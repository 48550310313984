import { DateBox, DateRangeBox, LoadIndicator, Popup } from "devextreme-react";
import DxButton from 'devextreme-react/button';
import DataGrid, { Button, Column, Item, Toolbar } from "devextreme-react/data-grid";
import PivotGrid, { FieldPanel } from "devextreme-react/pivot-grid";
import { exportPivotGrid } from "devextreme/excel_exporter";
import notify from "devextreme/ui/notify";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FILL_URI } from "../../constants";
import { useMsalToken } from "../../hooks/useToken";
import { EvalResult, EvalResultAnswers } from "../../models/evaluation";
import { DeleteResultWithIdAsync, GetDetailedReportForTeam, GetResultWithIdAsync } from "../../services/http-service";
import ArrayStore from "devextreme/data/array_store";
import { confirm } from "devextreme/ui/dialog";
import { Spinner } from "../../components/spinner/Spinner";
import moment from "moment";

export const ScoredResultsGrid: FunctionComponent<{ dataSource: PivotGridDataSource }> = ({ dataSource }) => {
    return (
        <PivotGrid
            onExporting={(e) => {
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('Team');
                exportPivotGrid({
                    component: e.component,
                    worksheet,
                }).then(() => {
                    workbook.xlsx.writeBuffer().then((buffer) => {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `data.xlsx`);
                    });
                });
                e.cancel = true;
            }}
            allowExpandAll={true}
            export={{ enabled: true }}
            allowFiltering={true}
            allowSorting={true}
            showBorders={true}
            showColumnTotals={false}
            showColumnGrandTotals={false}
            showRowTotals={false}
            dataSource={dataSource}>
            <FieldPanel
                showDataFields={true}
                showRowFields={true}
                visible={true}>
            </FieldPanel>
        </PivotGrid>
    );
};

export const DetailedResultsGrid: FunctionComponent<{ dataSource: EvalResult[], showDetailsReportDownload?: boolean }> = ({ dataSource, showDetailsReportDownload }) => {
    const token = useMsalToken();
    const [showDetails, setShowDetails] = useState<boolean>();
    const [showReportExport, setShowReportExport] = useState<boolean>();
    const [downloadingReport, setDownloadingReport] = useState<boolean>();
    const [resultId, setResultId] = useState<number>();
    const [resultDetails, setResultDetails] = useState<EvalResult>();
    const [entId, setEntId] = useState<number>();
    const [filename, setFilename] = useState<string | undefined>();
    const navigate = useNavigate();
    const grid = useRef<DataGrid>(null);
    const [[dateFrom, dateTo], setDateFromTo] = useState<[Date | undefined, Date | undefined]>([undefined, undefined]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params) {
            const r = Number.parseInt(params.get('result') ?? "");
            if (!Number.isNaN(r)) {
                setResultId(r);
            }
            const i = Number.parseInt(params.get('id') ?? "");
            if (!Number.isNaN(i)) {
                setEntId(i);
            }
        }
    }, []);

    let getEmployeeResultDetails = useCallback((id: number) => {
        if (!token) return;
        GetResultWithIdAsync(id, token).then(d => {
            if (d.ok) {
                setResultDetails(d.data);
                setShowDetails(true);
            } else {
                notify(`There was an error: ${d.error?.message}`, "error");
            }
        });
    }, [token]);

    const deleteResult = useCallback((id: number) => {
        if (!token) return;
        confirm("Are you sure you want to delete this evaluation?", "Confirm deletion").then(r => {
            if (r) {
                DeleteResultWithIdAsync(id, token).then(d => {
                    if (d.ok) {
                        notify("The result was deleted.", "success");
                        grid.current?.instance.getDataSource().store().remove(id);
                        grid.current?.instance.refresh();
                    } else {
                        notify("Unable to delete the result.", "error");
                    }
                });
            }
        });
    }, [token]);

    const onDateRangeChanged = useCallback(({ value: [startDate, endDate] }: any) => setDateFromTo([startDate, endDate]), [dateFrom, dateTo]);
    const downloadReport = () => {
        if (entId !== undefined && token && dateFrom && dateTo) {
            setDownloadingReport(true);
            GetDetailedReportForTeam(entId, dateFrom, dateTo, token).then(async r => {
                if (r.ok) {
                    const file = window.URL.createObjectURL(await r.blob());
                    const cd = r.headers.get("Content-Disposition");
                    var finalFilename = "report.xlsx";

                    if (filename) {
                        finalFilename = filename;
                    }
                    else if (cd) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(cd);
                        if (matches != null && matches[1]) {
                            finalFilename = matches[1].replace(/['"]/g, '');
                        }
                    } else {
                        finalFilename = `${entId}_${moment(dateFrom).format("YYYYMMDD")}_${moment(dateTo).format("YYYYMMDD")}`;
                    }

                    var a = document.createElement('a');
                    a.href = file;
                    a.download = finalFilename;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                    setDownloadingReport(false);
                } else {
                    throw r
                }
            }).catch(e => {
                setDownloadingReport(false);
                notify("Unable to download report due to error.", "error", 1000);
                console.error(e);
            });
        } else if (!dateFrom || !dateTo) {
            notify("Please select a date first.", "error", 1500);
        }
    }

    useEffect(() => {
        if (resultId !== undefined) {
            getEmployeeResultDetails(resultId!);
        }
    }, [getEmployeeResultDetails, resultId]);

    return (
        <>
            {showDetailsReportDownload &&
                <DxButton icon='download' text="Export Detailed Report" onClick={() => setShowReportExport(true)}></DxButton>}
            <DataGrid
                key="id"
                ref={grid}
                filterPanel={{ visible: true }}
                pager={{ visible: true, showPageSizeSelector: true, allowedPageSizes: [15, 30, 50] }}
                paging={{ pageSize: 15 }}
                export={{ enabled: true }}
                allowColumnResizing={true}
                filterRow={{ visible: true }}
                showBorders={true}
                onRowPrepared={(e) => {
                    if (e.data === undefined) return;
                    if (e.data.isZeroed) {
                        e.rowElement.className += " bg-danger border";
                    }
                }}
                dataSource={new ArrayStore({ data: dataSource, key: "id" })}>
                <Column type="buttons" caption="" width={120}>
                    <Button
                        icon="textdocument"
                        hint="Edit"
                        onClick={(e: any) =>
                            navigate({ pathname: FILL_URI, search: `?eval=${e.row.data.evaluationUid}&version=${e.row.data.evaluationVersion}&employee=${e.row.data.employeeId}&results=${e.row.data.id}` })} />
                    <Button
                        icon="orderedlist"
                        hint="See Detailed Answers"
                        onClick={(e: any) => getEmployeeResultDetails(e.row.data.id)} />
                    <Button
                        icon="remove"
                        hint="Delete Result"
                        onClick={(e: any) => deleteResult(e.row.data.id)} />
                </Column>
                <Column caption="Employee Name" dataField="employeeFirstName"
                    calculateCellValue={(e: EvalResult) => `${e.employeeFirstName} ${e.employeeLastName} `} allowFiltering={true} />
                <Column dataField="evaluationFilledAt" defaultSelectedFilterOperation='between' dataType="datetime" format="dd/MM/yyyy HH:mm"
                    defaultSortIndex={0} defaultSortOrder="desc" />
                <Column dataField="evaluationName" />
                <Column dataField="evaluationVersion" />
                <Column dataField="notes" />
                <Column dataField="evaluator" />
                <Column dataField="serviceItem.name" caption="Service Item" />
                <Column dataField="totalScore" dataType="number" />
                <Column dataField="totalMaxScore" dataType="number" />
                <Column dataField="softSkill" dataType="number" />
                <Column dataField="maxSoftSkill" dataType="number" />
                <Column caption="Score (%)" name={'Score (%)'} dataType="number" calculateCellValue={(e: EvalResult) => ((e.totalScore / e.totalMaxScore) * 100).toFixed(2)}
                    allowFiltering={true} />
                <Column caption="Soft Skill (%)" name={'Soft Skill (%)'} dataType="number" calculateCellValue={(e: EvalResult) => e.maxSoftSkill ? ((e.softSkill / e.maxSoftSkill) * 100).toFixed(2) : '-'} allowFiltering={true} />
                <Column dataField="isZeroed" />
                <Column calculateCellValue={(e: EvalResult) => !e.isZeroed ? `${((e.totalScore / e.totalMaxScore) * 100).toFixed(2)} / 100` : '00.00 / 100'} caption="Final Score" />
            </DataGrid >
            <Popup visible={showReportExport && showDetailsReportDownload} onVisibleChange={(v: boolean) => setShowReportExport(v)} width={480} height={260}>
                <div className="container d-flex flex-column">
                    <DateRangeBox className="mb-3" onValueChanged={onDateRangeChanged}></DateRangeBox>
                    <input type='text' className="form-control form-control-sm mb-3" placeholder="filename (optional)" onChange={e => setFilename(e.target.value)} />
                    <button className="btn btn-secondary" type="button" disabled={downloadingReport} onClick={downloadReport}>
                        {downloadingReport &&
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        }
                        {!downloadingReport &&
                            <span className="sr-only">Export...</span>
                        }
                    </button>
                </div>
            </Popup>
            <Popup visible={showDetails && resultDetails !== undefined} onVisibleChange={(v: boolean) => setShowDetails(v)}>
                <div className="overflow-auto h-100">
                    <div className="fs-6 fs-semibold">Service Item:</div><span>{resultDetails?.serviceItem?.name ?? ''}</span>
                    <div className="fs-6 fs-semibold">Notes:</div><span>{resultDetails?.notes ?? ''}</span>
                    <DataGrid
                        dataSource={resultDetails?.evaluationResultAnswers}
                        showBorders={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        export={{
                            enabled: true,
                            //fileName: `${resultDetails?.employeeId}. ${resultDetails?.employeeFirstName} ${resultDetails?.employeeLastName} ${resultDetails?.evaluationName} ${resultDetails?.evaluationFilledAt}`,
                        }}>
                        <Column dataField="question.question" caption="Question" />
                        <Column dataType="boolean" calculateCellValue={(e: EvalResultAnswers) => e.question.mustBeAnswerUid && e.question.mustBeAnswerUid !== e.answer.uid} caption="Zeroed" />
                        <Column dataField="answer.answer" caption="Answer" />
                        <Column dataField="answer.multiplier" caption="Multiplier" />
                        <Column dataField="question.score" caption="Score" />
                        <Column dataField="question.softSkill" caption="Soft Skill" />
                        <Column dataField="notes" caption="Notes" />
                    </DataGrid>
                </div>
            </Popup>
        </>
    );
};

export const RawDataResultsGrid: FunctionComponent<{ dataSource: EvalResult[] }> = ({ dataSource }) => {
    return (
        <DataGrid
            pager={{ allowedPageSizes: [10, 25, 50], showPageSizeSelector: true }}
            searchPanel={{ visible: true }}
            filterPanel={{ visible: true }}
            export={{ enabled: true }}
            columnChooser={{ enabled: true, mode: 'select' }}
            key="id"
            summary={{
                groupItems: [{
                    summaryType: 'avg',
                    column: 'pctScore',
                    displayFormat: 'Score: {0}%',
                    valueFormat: (v: number) => (v * 100).toFixed(2)
                }, {
                    summaryType: 'avg',
                    column: 'pctSoft',
                    displayFormat: 'Soft Skill: {0}%',
                    valueFormat: (v: number) => (v * 100).toFixed(2)
                },
                ]
            }}
            groupPanel={{ visible: true }}
            grouping={{ autoExpandAll: true, contextMenuEnabled: true }}
            onRowPrepared={(e) => {
                if (e.data === undefined) return;
                if (e.data.isZeroed) {
                    e.rowElement.className += " bg-danger border";
                }
            }}
            dataSource={dataSource.flatMap(p => {
                return p.evaluationResultSections.flatMap(s => {
                    return {
                        resultId: p.id,
                        sectionId: s.id,
                        evaluationName: p.evaluationName,
                        evaluationUid: p.evaluationUid,
                        evaluationVersion: p.evaluationVersion,
                        pctSoft: Number.isNaN(s.softSkill / s.maxSoftSkill) ? undefined : s.softSkill / s.maxSoftSkill,
                        pctScore: p.isZeroed ? 0 : s.totalScore / s.maxTotalScore,
                        sectionName: s.section.sectionName,
                        employeeId: p.employeeId,
                        employeeName: `${p.employeeFirstName} ${p.employeeLastName}`,
                        evaluationFilledAt: p.evaluationFilledAt,
                        isZeroed: p.isZeroed,
                    }
                });
            })}
        >
            <Column dataField="resultId" groupIndex={0} />
            <Column dataField="evaluationName" />
            <Column dataField="evaluationFilledAt" dataType="datetime" />
            <Column dataField="evaluationUid" />
            <Column dataField="evaluationVersion" />
            <Column dataField="employeeName" />
            <Column dataField="sectionName" />
            <Column dataField="pctSoft" format="percent" dataType='number' />
            <Column dataField="pctScore" format="percent" dataType='number' />
        </DataGrid>
    )
};