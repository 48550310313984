import moment from 'moment';
import { Campaign, CampaignUpdateDto } from '../models/campaign';
import { Company, Position, Transaction } from '../models/company';
import { Employee } from '../models/employee';
import { AnswersForScoring, Eval, EvalAnswerType, EvalResult, EvalServiceItem } from '../models/evaluation';
import { Team } from '../models/team';
import { Evaluator, User } from '../models/user';

export type ErrorResponse = {
    message: string,
};

export type HttpResult<T = {}> = {
    ok?: boolean,
    status?: number,
    data?: T,
    error?: ErrorResponse,
};

export const fetchJson = <T>(uri: string, init?: RequestInit) => {
    if (uri.startsWith('/'))
        uri = uri.substring(1);
    return fetch(`${process.env.REACT_APP_API_URI}/${uri}`, init)
        .then(r => r.json()
            .then((d) => {
                if (r.ok) return { ok: r.ok, status: r.status, data: d } as HttpResult<T>;
                else return { ok: r.ok, status: r.status, error: d } as HttpResult<T>;
            }))
        .catch(e => { console.log(e); return { error: { message: e } } as HttpResult<T> });
}

export const HasAccessToEval = (token: string) => {
    return fetchJson<{ hasAccess: boolean }>(`api/user/eval`, {
        headers: { 'Authorization': token }
    });
};

export const GetIsAdminAsync = (token: string) => {
    return fetchJson<{ isAdmin: boolean }>(`api/user/isadmin`, {
        headers: { 'Authorization': token }
    });
};

export const GetCampaignsAsync = (companyId: number, token: string) => {
    return fetchJson<Campaign[]>(`api/campaigns?companyId=${companyId}`, {
        headers: { 'Authorization': token }
    });
};

export const AddEmployee = (employee: Employee, token: string) => {
    return fetchJson(`api/employee`, {
        headers: { 'Authorization': token },
        method: 'post',
        body: JSON.stringify(employee)
    });
};

export const UpdateEmployee = (employee: Employee, token: string) => {
    return fetchJson(`api/employee`, {
        headers: { 'Authorization': token },
        method: 'put',
        body: JSON.stringify(employee)
    });
};

export const GetEmployee = (employeeId: number, token: string) => {
    return fetchJson<Employee>(`api/employee/${employeeId}`, {
        headers: { 'Authorization': token },
    });
};

export const UploadOptishiftData = (companyId: number, token: string) => {
    return fetchJson(`api/employee/upload?companyId=${companyId}`, {
        headers: { 'Authorization': token },
        method: 'post',
    });
};

export const UpdateCampaignAsync = (token: string, campaignUpdate: CampaignUpdateDto) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/campaign/update`, {
        headers: { 'Authorization': token },
        method: 'post',
        body: JSON.stringify(campaignUpdate)
    });
};

export const GetUsersAsync = (token: string, all: boolean = false) => {
    return fetchJson<User[]>(`api/users?all=${all}`, {
        headers: { 'Authorization': token }
    });
};

export const GetEvaluatorsAsync = (token: string, all: boolean = false) => {
    return fetchJson<Evaluator[]>(`api/evaluators?all=${all}`, {
        headers: { 'Authorization': token }
    });
};

export const RegisterUserAsync = (token: string, user: User) => {
    return fetchJson(`api/user`, {
        headers: { 'Authorization': token },
        method: 'post',
        body: JSON.stringify(user)
    });
};

export const UpdateUserAsync = (token: string, user: User) => {
    return fetchJson(`api/user`, {
        headers: { 'Authorization': token },
        method: 'put',
        body: JSON.stringify(user)
    });
};

export const DeleteUserAsync = (token: string, email: string) => {
    return fetchJson(`api/user?email=${email}`, {
        headers: { 'Authorization': token },
        method: 'delete'
    });
};

export const GetCompanyPositionsAsync = (token: string, companyId: number) => {
    return fetchJson<Position[]>(`api/company/positions?companyId=${companyId}`, {
        headers: { 'Authorization': token }
    });
};

export const DownloadFileAsync = (token: string, filename: string) => {
    return fetchJson(`api/transaction/download?filename=${encodeURI(filename)}`, {
        headers: { 'Authorization': token },
    });
};

export const GetTransactionsAsync = (token: string, companyId: number) => {
    return fetchJson<Transaction>(`api/company/transactions?companyId=${companyId}`, {
        headers: { 'Authorization': token }
    });
};

export const AddPositionToCompanyAsync = (token: string, position: Position, companyId: number) => {
    return fetchJson(`api/company/position?companyId=${companyId}`, {
        headers: { 'Authorization': token },
        method: 'post',
        body: JSON.stringify(position),
    });
};

export const UpdatePositionNameAsync = (token: string, position: Position, companyId: number) => {
    return fetchJson(`api/company/positions?companyId=${companyId}`, {
        headers: { 'Authorization': token },
        method: 'put',
        body: JSON.stringify([position] as Position[]),
    });
};

export const GetEvaluationsForTeamAsync = async (token: string, teamId: number) => {
    return fetchJson<Eval[]>(`api/evals?teamid=${teamId}`, {
        headers: { 'Authorization': token },
        method: 'get',
    });
};

export const GetEvaluationWithUidAsync = async (token: string, uid: string, version: number) => {
    return fetchJson<Eval>(`api/eval?uid=${uid}&version=${version}`, {
        headers: { 'Authorization': token },
        method: 'get',
    });
};

export const GetAnswersAsync = async (token: string) => {
    return fetchJson<EvalAnswerType[]>(`api/answer`, {
        headers: { 'Authorization': token },
        method: 'get',
    });
};

export const GetAnswersForEvalAsync = async (token: string, uid: string, version: number) => {
    return fetchJson<EvalAnswerType[]>(`api/answer/eval?uid=${uid}&version=${version}`, {
        headers: { 'Authorization': token },
        method: 'get',
    });
};

export const InsertOrUpdateEvalAsync = async (token: string, evaluation: Eval, insertNew: boolean) => {
    return fetchJson<Eval>(`api/eval`, {
        headers: { 'Authorization': token },
        method: insertNew ? 'post' : 'put',
        body: JSON.stringify(evaluation)
    });
};

export const InsertOrUpdateAnswerAsync = async (token: string, answer: EvalAnswerType, insertNew: boolean) => {
    return fetchJson<EvalAnswerType>(`api/answer`, {
        headers: { 'Authorization': token },
        method: insertNew ? 'post' : 'put',
        body: JSON.stringify(answer)
    });
};

export const GetCompaniesAsync = (token: string) => {
    return fetchJson<Company[]>('api/user/companies', {
        headers: { 'Authorization': token }
    });
};

export const GetCompanyEmployeesAsync = (companyId: number, token: string) => {
    return fetchJson<Employee[]>(`api/employees?companyId=${companyId}`, {
        headers: { 'Authorization': token }
    });
};

export const InsertTeamAsync = (team: Team, token: string) => {
    return fetchJson<Team>(`api/team`, {
        headers: { 'Authorization': token },
        method: 'post',
        body: JSON.stringify(team),
    });
};

export const InsertEmployeeToTeamAsync = (teamId: number, employeeId: number, token: string) => {
    return fetchJson<Team>(`api/team.employee?teamid=${teamId}&employeeid=${employeeId}`, {
        headers: { 'Authorization': token },
        method: 'post',
    });
};

export const GetTeamWithIdAsync = (teamId: number, token: string) => {
    return fetchJson<Team>(`api/team?teamid=${teamId}`, {
        headers: { 'Authorization': token }
    });
};

export const GetCompanyTeamsAsync = (companyId: number, token: string) => {
    return fetchJson<Team[]>(`api/teams?companyId=${companyId}`, {
        headers: { 'Authorization': token }
    });
};

export const UpdateTeamAsync = (team: Team, token: string) => {
    return fetchJson<Team>(`api/team`, {
        headers: { 'Authorization': token },
        method: 'put',
        body: JSON.stringify(team),
    });
};

export const SendAnswersForScoring = (answers: AnswersForScoring, token: string) => {
    return fetchJson<EvalResult>(`api/score`, {
        headers: { 'Authorization': token },
        method: 'post',
        body: JSON.stringify(answers),
    });
};

export const GetResultsForTeamAsync = (teamId: number, token: string) => {
    return fetchJson<EvalResult[]>(`api/results?teamid=${teamId}`, {
        headers: { 'Authorization': token },
        method: 'get',
    });
};

export const GetResultsForEmployeeAsync = (employeeId: number, token: string) => {
    return fetchJson<EvalResult[]>(`api/results?employeeid=${employeeId}`, {
        headers: { 'Authorization': token },
        method: 'get',
    });
};

export const GetResultsForEvaluatorAsync = (evaluatorId: number, token: string) => {
    return fetchJson<EvalResult[]>(`api/results?evaluatorid=${evaluatorId}`, {
        headers: { 'Authorization': token },
        method: 'get',
    });
};

export const GetResultsForCompanyAsync = (companyId: number, token: string) => {
    return fetchJson<EvalResult[]>(`api/results?companyid=${companyId}`, {
        headers: { 'Authorization': token },
        method: 'get',
    });
};

export const GetResultWithIdAsync = (resultId: number, token: string) => {
    return fetchJson<EvalResult>(`api/result/${resultId}`, {
        headers: { 'Authorization': token },
        method: 'get',
    });
};

export const DeleteResultWithIdAsync = (resultId: number, token: string) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/result/${resultId}`, {
        headers: { 'Authorization': token },
        method: 'delete',
    });
}

export const AddServiceItemAsync = (serviceItem: EvalServiceItem, token: string) => {
    return fetchJson<EvalServiceItem>(`api/serviceitem`, {
        headers: { 'Authorization': token },
        method: 'post',
        body: JSON.stringify(serviceItem),
    });
};

export const GetServiceItemsForTeamAsync = (evalUid: string, evalVersion: number, token: string) => {
    return fetchJson<EvalServiceItem[]>(`api/serviceitem?evaluationUid=${evalUid}&evaluationVersion=${evalVersion}`, {
        headers: { 'Authorization': token },
        method: 'get',
    });
};

export const GetAverageScoreForEvalAsync = (evalUid: string, token: string) => {
    return fetchJson<{ avgScore: number }>(`api/eval/avg?uid=${evalUid}`, {
        headers: { 'Authorization': token },
        method: 'get',
    });
};

export const GetDetailedReportForTeam = (teamId: number, from: Date, to: Date, token: string) => {

    return fetch(`${process.env.REACT_APP_API_URI}/api/report/team?teamid=${teamId}&from=${moment(from).format('YYYY-MM-DD')}&to=${moment(to).format('YYYY-MM-DD')}`, {
        headers: { 'Authorization': token }
    });
}