import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { EvalResult } from "../../models/evaluation";

export type ResultsMode = 'company' | 'team' | 'employee' | 'evaluator';

export const createPivotGridDataSource = (data: EvalResult[], resultsMode?: ResultsMode) => {
    if (!resultsMode) {
        return undefined;
    }

    const d = data.flatMap(x => {
        return {
            employee: `${x.employeeId} - ${x.employeeFirstName} ${x.employeeLastName}`,
            score: Number.isNaN(x.totalScore) || x.isZeroed ? 0 : (x.totalScore ?? 0),
            maxScore: x.totalMaxScore,
            softSkill: x.softSkill,
            maxSoftSkill: x.maxSoftSkill,
            date: x.evaluationFilledAt,
            evaluation: `${x.evaluationName} v.${x.evaluationVersion}`,
            evaluationName: x.evaluationName,
            evaluationVersion: x.evaluationVersion,
            evalId: `${x.evaluationUid} - ${x.evaluationVersion}`,
            serviceItem: x.serviceItem?.name ?? '-',
            team: `${x.teamId}. ${x.teamName}`,
            evaluator: x.evaluator,
        };
    });

    const ds = new PivotGridDataSource({
        fields: [{
            dataField: 'evaluation',
            area: 'row',
            width: 100,
        }, {
            dataField: 'serviceItem',
            area: 'filter',
            width: 100,
        }, {
            dataField: 'date',
            dataType: 'date',
            area: 'column',
        }, {
            dataField: 'score',
            caption: 'Score',
            area: 'filter',
            dataType: 'number',
            summaryType: 'sum',
        }, {
            dataField: 'maxScore',
            caption: 'Max Score',
            area: 'filter',
            dataType: 'number',
            summaryType: 'sum',
        }, {
            calculateCustomSummary: options => {
                switch (options.summaryProcess) {
                    case "start":
                        options.totalValue = { total: 0, max: 0 };
                        break;
                    case "calculate":
                        options.totalValue.total += options.value.score;
                        options.totalValue.max += options.value.maxScore;
                        break;
                    case "finalize":
                        if (options.totalValue.max > 0) {
                            options.totalValue = (options.totalValue.total / options.totalValue.max * 100.0).toFixed(0) + '%';
                        } else {
                            options.totalValue = '-';
                        }
                        break;
                }
                return 0;
            },
            caption: 'Score (%)',
            area: 'data',
            dataType: 'number',
            summaryType: 'custom',
        }, {
            dataField: 'softSkill',
            caption: 'Soft Skill',
            area: 'filter',
            dataType: 'number',
            summaryType: 'sum',
        }, {
            dataField: 'maxSoftSkill',
            caption: 'Max Soft Skill',
            area: 'filter',
            dataType: 'number',
            summaryType: 'sum',
        }, {
            calculateCustomSummary: options => {
                switch (options.summaryProcess) {
                    case "start":
                        options.totalValue = { total: 0, max: 0 };
                        break;
                    case "calculate":
                        options.totalValue.total += options.value.softSkill;
                        options.totalValue.max += options.value.maxSoftSkill;
                        break;
                    case "finalize":
                        if (options.totalValue.max > 0) {
                            options.totalValue = (options.totalValue.total / options.totalValue.max * 100.0).toFixed(0) + '%';
                        } else {
                            options.totalValue = '-';
                        }
                        break;
                }
                return 0;
            },
            caption: 'Soft Skill (%)',
            area: 'data',
            dataType: 'number',
            summaryType: 'custom',
            format: (n: number) => n.toFixed(0),
        }, {
            calculateCustomSummary: options => {
                switch (options.summaryProcess) {
                    case "start":
                        options.totalValue = 0;
                        break;
                    case "calculate":
                        options.totalValue += 1;
                        break;
                    case "finalize":
                        break;
                }
                return 0;
            },
            caption: 'Number of Evaluations',
            area: 'data',
            dataType: 'number',
            summaryType: 'custom',
        },
        ],
        store: d,
    });

    if (resultsMode !== 'employee') {
        ds.fields().unshift({
            caption: 'Employee',
            dataField: 'employee',
            area: 'row',
            width: 120
        });
    }
    if (resultsMode === 'company') {
        ds.fields().unshift({
            caption: 'Team',
            dataField: 'team',
            area: 'row',
            width: 120
        });
    }

    return ds;
};