import { FunctionComponent } from "react";
import { EvalAnswerType, EvalQuestion } from "../../models/evaluation";
import { ValidationGroup } from "../../types/validation";
import { RangeValidator, RequiredValidator } from "../Validator";
import { EvalEditorSidebar } from "./EvalEditorSidebar";

export declare type EvalEditorDetailsProps = {
    question?: EvalQuestion,
    onQuestionUpdated?: (question: EvalQuestion) => void,
    answerTypes?: EvalAnswerType[],
    validationGroup: ValidationGroup,
};

export const EvalEditorDetails: FunctionComponent<EvalEditorDetailsProps> = ({ question, onQuestionUpdated, answerTypes, validationGroup }) => {
    return (
        <EvalEditorSidebar title="Details" img="clipboard-fill" className="border-start">
            <div className='bg-white pt-1 px-1 border-dashed'>
                {question !== undefined &&
                    <>
                        <div className="row mb-1 mx-1">
                            <div className="col">
                                <label className="form-label" htmlFor="questionInput">Question:</label>
                                <textarea
                                    id="questionInput"
                                    rows={2}
                                    onChange={(e) => { question.question = e.target.value; onQuestionUpdated?.call(undefined, question); }}
                                    className='form-control rounded-0'
                                    value={question.question}
                                    required={true} />
                                <RequiredValidator group={validationGroup} value={question.question} />
                            </div>
                        </div>
                        <div className="row mb-1 mx-1">
                            <div className="col">
                                <label className="form-label" htmlFor="answerTypeInput">Answer Type:</label>
                                <select
                                    className="form-select"
                                    id="answerTypeInput"
                                    onChange={(e) => {
                                        if (e.target.selectedIndex !== 0 && answerTypes !== undefined) {
                                            question.answerTypeUid = answerTypes![e.target.selectedIndex - 1].uid;
                                            question.answerTypeVersion = answerTypes![e.target.selectedIndex - 1].version;
                                        } else {
                                            question.answerTypeUid = undefined;
                                            question.answerTypeVersion = undefined;
                                        }
                                        question.mustBeAnswerUid = undefined;
                                        question.mustBeAnswerVersion = undefined;
                                        onQuestionUpdated?.call(undefined, question);
                                    }}
                                    value={question.answerTypeUid ?? -1}>
                                    <option value={-1}></option>
                                    {answerTypes?.map((a, i) =>
                                        <option value={a.uid} key={i} >{a.name}</option>
                                    )}
                                </select>
                                <RequiredValidator group={validationGroup} value={question.answerTypeUid} />
                            </div>
                        </div>
                        {question.answerTypeUid &&
                            <div className="row mb-1 mx-1">
                                <div className="col">
                                    <label className="form-label" htmlFor="answerTypeInput">Must be answer (Optional):</label>
                                    <select
                                        className="form-select"
                                        id="answerTypeInput"
                                        onChange={(e) => {
                                            if (e.target.selectedIndex !== 0 && answerTypes !== undefined) {
                                                const at = answerTypes.find(a => a.uid === question.answerTypeUid);
                                                if (!at) return;
                                                question.mustBeAnswerUid = at?.evaluationAnswers![e.target.selectedIndex - 1].uid;
                                                question.mustBeAnswerVersion = at?.evaluationAnswers![e.target.selectedIndex - 1].version;
                                            } else {
                                                question.mustBeAnswerUid = undefined;
                                                question.mustBeAnswerVersion = undefined;
                                            }
                                            onQuestionUpdated?.call(undefined, question);
                                        }}
                                        value={question.mustBeAnswerUid ?? -1}>
                                        <option value={-1}></option>
                                        {answerTypes?.filter(a => a.uid === question.answerTypeUid).flatMap(a => a.evaluationAnswers).map((a, i) =>
                                            <option value={a.uid} key={i} >{a.answer}</option>
                                        )}
                                    </select>
                                </div>
                                <span className="fw-light fst-italic">If you select an answer, the entire evaluation will be scored with 0 (zero),
                                    if this question doesn't have the specified answer.</span>
                            </div>
                        }
                        <div className="row mb-1 mx-1">
                            <div className="col">
                                <label className="form-label" htmlFor="scoreInput">Score:</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="scoreInput"
                                    value={question.score ?? ''}
                                    onChange={(e) => {
                                        const n = Number.parseFloat(e.target.value);
                                        if (Number.isNaN(n)) {
                                            question.score = undefined;
                                        } else {
                                            question.score = n;
                                        }
                                        onQuestionUpdated?.call(undefined, question);
                                    }} />
                                <RequiredValidator group={validationGroup} value={question.score} />
                                <RangeValidator group={validationGroup} value={question.score} min={0} />
                            </div>
                        </div>
                        <div className="row mb-1 mx-1">
                            <div className="col">
                                <label className="form-label" htmlFor="softSkillInput">Soft Skill:</label>
                                <input type="number"
                                    className="form-control"
                                    id="softSkillInput"
                                    value={question.softSkill ?? ''}
                                    onChange={(e) => {
                                        const n = Number.parseFloat(e.target.value);
                                        if (Number.isNaN(n)) {
                                            question.softSkill = undefined;
                                        } else {
                                            question.softSkill = n;
                                        }
                                        onQuestionUpdated?.call(undefined, question);
                                    }} />
                                <RangeValidator group={validationGroup} value={question.softSkill} min={0} />
                            </div>
                        </div>
                    </>
                }
            </div>
        </EvalEditorSidebar >
    );
};