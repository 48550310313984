import { FunctionComponent } from "react";
import { EvalEditorSidebar } from "./EvalEditorSidebar";
import './EvalComponents.scss';

export const EvalEditorActionBtn: FunctionComponent<{ onClick?: () => void, text: string }> = ({ onClick, text }) => {
    return (
        <div className="row px-4">
            <button
                onClick={onClick}
                type='button'
                className='py-1 mb-1 w-100 fs-5 fw-semibold btn btn-secondary'
            >
                {text}
            </button>
        </div >
    );
};

export const EvalEditorActions: FunctionComponent<{ actions: { name: string, callback?: () => void }[] }> = ({ actions }) => {
    return (
        <EvalEditorSidebar title="Actions" img="x-diamond-fill" className="border-end">
            <div className='container text-center pt-1 border-dashed'>
                {actions.map((a, i) =>
                    <EvalEditorActionBtn key={i} onClick={a.callback} text={a.name} />
                )}
            </div>
        </EvalEditorSidebar>
    );
};