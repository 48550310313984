import notify from "devextreme/ui/notify";
import { useEffect, useRef, useState } from "react";
import CompaniesDropdown from "../../components/companies-dropdown/CompaniesDropdown";
import { FullSpinner } from "../../components/spinner/Spinner";
import { RequiredValidator } from "../../components/Validator";
import { useMsalToken } from "../../hooks/useToken";
import { useValidationGroup } from "../../hooks/useValidation";
import { Employee } from "../../models/employee";
import { Team } from "../../models/team";
import { GetCompanyEmployeesAsync, GetCompanyTeamsAsync, GetTeamWithIdAsync, InsertTeamAsync, UpdateTeamAsync } from "../../services/http-service";
import './Teams.scss'

export const Teams = () => {
    const token = useMsalToken();
    const group = useValidationGroup();
    const teamNameRef = useRef<HTMLInputElement | null>(null);

    const [companyId, setCompanyId] = useState<number>();
    const [companyEmployees, setCompanyEmployees] = useState<Employee[]>();
    const [teams, setTeams] = useState<Team[]>([]);

    const [isLoading, setIsLoading] = useState<number>(0);

    const [addingTeam, setAddingTeam] = useState(false);
    const [addingEmployees, setAddingEmployees] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const [selectedTeamIndex, setSelectedTeamIndex] = useState<number>();
    const [selectedTeam, setSelectedTeam] = useState<Team>();

    const [teamEmployees, setTeamEmployees] = useState<Map<string, Employee>>();
    const [searchTerm, setSearchTerm] = useState<string>();
    const [teamSearchTerm, setTeamSearchTerm] = useState<string>();

    useEffect(() => {
        teamNameRef?.current?.select();
    }, [addingTeam]);

    useEffect(() => {
        if (!companyId || !token) {
            return;
        }
        setSelectedTeam(undefined);
        setSelectedTeamIndex(undefined);
        setAddingEmployees(false);
        setIsLoading(1);
        GetCompanyTeamsAsync(companyId, token)
            .then((d) => {
                if (d.ok && d.data) {
                    setTeams(d.data);
                } else {
                    notify(`There was an error while getting the company teams: ${d.error?.message}`, "error", 2300);
                }
            });
        GetCompanyEmployeesAsync(companyId, token)
            .then((d) => {
                if (d.ok) {
                    setCompanyEmployees(d.data);
                } else {
                    notify(`There was an error while getting the company employees: ${d.error?.message}`, "error", 2300);
                }
                setIsLoading(0);
            });
    }, [companyId, token]);

    const addNewTeam = () => {
        if (!teamNameRef.current || !token) return;
        let val = teamNameRef.current.value;
        teamNameRef.current.value = '';
        teamNameRef.current.blur();
        if (companyId === undefined || !val) return;
        setIsLoading(1);
        InsertTeamAsync({ name: val, companyId: companyId }, token)
            .then((d) => {
                if (d.ok && d.data) {
                    setTeams([...teams, d.data]);
                    if (d.data.id)
                        selectTeam(d.data.id);
                } else {
                    notify(`There was an error while adding the team: ${d.error?.message}`, "error", 2300);
                }
                setIsLoading(0);
            });
    };

    const selectTeam = (teamId: number) => {
        if (!token) return;
        setIsLoading(isLoading + 1);
        GetTeamWithIdAsync(teamId, token)
            .then((d) => {
                if (d.ok) {
                    setSelectedTeam(d.data);
                    setTeamEmployees(new Map(d.data?.employees?.map((e) => [e.employeeId, e] as [string, Employee])));
                } else {
                    notify(`There was an error while getting the team's details: ${d.error?.message}`, "error", 2300);
                }
                setIsLoading(0);
            });
        setSelectedTeamIndex(teamId);
        setAddingEmployees(false);
        setIsDirty(false);
    };

    const goToEditMode = (editing: boolean, save: boolean = false) => {
        if (!selectedTeam || !group.causeValidation()) return;
        if (!editing) {
            if (save) {
                saveEditingTeam();
            } else {
                if (selectedTeamIndex !== undefined)
                    selectTeam(selectedTeamIndex);
            }
        }
        setAddingEmployees(editing);
    };

    const addEmployee = (e: Employee) => {
        selectedTeam?.employees?.push(e);
        setTeamEmployees(new Map(selectedTeam?.employees?.map((e) => [e.employeeId, e] as [string, Employee])));
        setIsDirty(true);
    };

    const removeEmployee = (i: number) => {
        if (!selectedTeam) return;
        selectedTeam.employees?.splice(i, 1);
        setTeamEmployees(new Map(selectedTeam?.employees?.map((e) => [e.employeeId, e] as [string, Employee])));
        setIsDirty(true);
    };

    const saveEditingTeam = () => {
        if (!token || !selectedTeam) return;
        setIsLoading(isLoading + 1);
        UpdateTeamAsync(selectedTeam, token)
            .then((d) => {
                if (d.ok && d.data) {
                    setSelectedTeam(d.data);
                    setIsDirty(false);
                    notify(`Changes were save successfully!`, "success", 1500);
                } else {
                    notify(`There was an error while updating the team: ${d.error?.message}`, "error", 2300);
                }
                setIsLoading(isLoading - 1);
            })
    };

    return (
        <div className="mx-4 full-height">
            {isLoading > 0 && <FullSpinner />}
            <div className="row h-100">
                <div className="col-2">
                    <div className="row">
                        <CompaniesDropdown onSelectedCompanyChanged={(c) => { setCompanyId(c?.id) }} />
                    </div>
                    <div className="row text-center w-100 justify-content-center fs-6 fw-semibold">
                        Teams
                    </div>
                    <div className="row">
                        <input placeholder="Search" type="text" className="form-control" value={teamSearchTerm ?? ''}
                            onChange={(e) => setTeamSearchTerm(e.currentTarget.value)} />
                    </div>
                    <div className="row bg-light">
                        <div className="list-group rounded-0 p-1 user-select-none">
                            <div onClick={() => setAddingTeam(true)} className="list-group-item list-group-item-action" >
                                {!addingTeam &&
                                    <div className="fst-italic fw-semibold">Create team...</div>
                                }
                                {addingTeam &&
                                    <form onSubmit={(e) => { e.preventDefault(); addNewTeam(); }}>
                                        <div className="d-flex flex-row">
                                            <input
                                                className="form-control"
                                                ref={teamNameRef}
                                                type='text'
                                                onBlur={() => setAddingTeam(false)} />
                                        </div>
                                    </form>
                                }
                            </div>
                            {teams.filter((t) => !teamSearchTerm || t.name.includes(teamSearchTerm)).map((t, i) =>
                                <div key={i} onClick={() => selectTeam(t.id!)} className="list-group-item list-group-item-action">{t.name}</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col text-center h-100">
                    {selectedTeam &&
                        <div className="row h-100 overflow-auto">
                            <div className="row mb-2 sticky-top bg-white my-2" style={{ maxHeight: "40px" }}>
                                <div className="col-2">
                                    <button
                                        className="btn btn-sm btn-success text-white w-100 h-100"
                                        type="button"
                                        onClick={() => goToEditMode(false, true)}
                                        disabled={!isDirty}>
                                        <i className="bi bi-save-fill"></i> Save
                                    </button>
                                </div>
                                <div className="col-2">
                                    <button
                                        className="btn btn-sm btn-danger text-white w-100 h-100"
                                        type="button"
                                        onClick={() => goToEditMode(false)}
                                        disabled={!isDirty && !addingEmployees}>
                                        <i className="bi bi-x-circle-fill"></i> Cancel
                                    </button>
                                </div>
                                {!addingEmployees &&
                                    <>
                                        <div className="col-2">
                                            <button
                                                className="btn btn-sm btn-primary text-white w-100 h-100"
                                                type="button"
                                                onClick={() => goToEditMode(true)}>
                                                <i className="bi bi-plus-square-fill"></i> Add Employees
                                            </button>
                                        </div>
                                        <div className="col-2">
                                            <RequiredValidator value={selectedTeam.name} group={group} />
                                            <input type="text" className="form-control" value={selectedTeam.name} onChange={(e) => { setIsDirty(true); selectedTeam.name = e.target.value; setSelectedTeam({ ...selectedTeam }); }} />
                                        </div>
                                    </>
                                }
                                <div className="col-2">
                                    <input placeholder="Search" type="text" className="form-control" value={searchTerm} onChange={(e) => setSearchTerm(e.currentTarget.value)} />
                                </div>
                            </div>
                            <div className="border">
                                <table className={`table table-striped`}>
                                    <thead>
                                        <tr>
                                            <th>Employee Id</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {addingEmployees &&
                                            companyEmployees
                                                ?.filter((e) => !teamEmployees?.has(e.employeeId) && (!searchTerm || e.firstName.includes(searchTerm) ||
                                                    e.lastName.includes(searchTerm) || e.employeeId.includes(searchTerm)))
                                                .map((e, i) => {
                                                    return <tr key={i} className='table-success'>
                                                        <td>{e.employeeId}</td>
                                                        <td>{e.firstName}</td>
                                                        <td>{e.lastName}</td>
                                                        <td><button
                                                            type="button"
                                                            className="btn btn-secondary btn-sm"
                                                            onClick={() => addEmployee(e)}>
                                                            <i className="bi bi-plus"></i></button>
                                                        </td>
                                                    </tr>
                                                }
                                                )}
                                        {!addingEmployees && selectedTeam.employees
                                            ?.filter((e) => !searchTerm || e.firstName.includes(searchTerm) || e.lastName.includes(searchTerm) ||
                                                e.employeeId.includes(searchTerm))
                                            .map((e, i) =>
                                                <tr key={i}>
                                                    <td>{e.employeeId}</td>
                                                    <td>{e.firstName}</td>
                                                    <td>{e.lastName}</td>
                                                    <td><button
                                                        type="button"
                                                        className="btn btn-secondary btn-sm"
                                                        onClick={() => removeEmployee(i)}>
                                                        <i className="bi bi-trash"></i></button>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    )
};