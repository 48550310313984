import { FunctionComponent, useState } from "react";
import { useValidationGroup } from "../../hooks/useValidation";
import { EvalAnswer, EvalAnswerType } from "../../models/evaluation";
import { InsertOrUpdateAnswerAsync } from "../../services/http-service";
import { RangeValidator, RequiredValidator } from "../Validator";
import { EvalEditorSidebar } from "./EvalEditorSidebar";
import notify from 'devextreme/ui/notify';
import { Spinner } from "../spinner/Spinner";
import { useMsalToken } from "../../hooks/useToken";

export interface EvalEditorAnswerDetailsProps {
    answer?: EvalAnswerType,
    onAnswerTypeUpdated?: (answer: EvalAnswerType) => void,
    onAnswerUpdated?: (i: number, answer: EvalAnswer) => void,
}

export const EvalEditorAnswerDetails: FunctionComponent<EvalEditorAnswerDetailsProps> = ({ answer, onAnswerUpdated, onAnswerTypeUpdated }) => {
    const token = useMsalToken();
    const group = useValidationGroup();
    const [isLoading, setLoading] = useState(false);

    return (
        <EvalEditorSidebar title="Details" img="clipboard-fill" className="border-start" >
            <div className='bg-white eval-sidebar'>
                {answer !== undefined &&
                    <div>
                        <div className="bg-light border" style={{ width: '100%', position: 'sticky', top: '0', marginTop: 0 }}>
                            <span
                                onClick={() => {
                                    answer.evaluationAnswers.push({ answer: 'new answer', multiplier: 0, isNeutral: false });
                                    onAnswerTypeUpdated?.call(undefined, answer);
                                }}
                                className="btn btn-dark btn-sm m-2">
                                <i className="bi bi-plus-square-fill fs-6" /> Add Answer
                            </span>
                            <span
                                onClick={() => {
                                    if (answer && group.causeValidation()) {
                                        setLoading(true);
                                        if (!token) return;
                                        InsertOrUpdateAnswerAsync(token, answer, answer.uid === undefined)
                                            .then(d => {
                                                if (d.ok && d.data !== undefined) {
                                                    onAnswerTypeUpdated?.call(undefined, d.data);
                                                    notify('The changes were saved successfully!', 'success', 1800);
                                                } else {
                                                    notify(`There was an error while saving: ${d.error?.message}`, 'error', 2500);
                                                }
                                                setLoading(false);
                                            });
                                    }
                                }}
                                className="btn btn-dark btn-sm m-2">
                                <i className="bi bi-save-fill fs-6" /> Save Changes
                                {isLoading &&
                                    <Spinner size="sm" />
                                }
                            </span>
                        </div>
                        <div className="row mb-1 mx-1">
                            <div className="col">
                                <label className="form-label" htmlFor="questionInput">Name:</label>
                                <input
                                    type={'text'}
                                    id="questionInput"
                                    className='form-control rounded-0'
                                    onChange={(e) => { answer.name = e.target.value; onAnswerTypeUpdated?.call(undefined, answer); }}
                                    value={answer.name}
                                    readOnly={false}
                                    required />
                                <RequiredValidator group={group} value={answer.name} />
                            </div>
                        </div>
                        <div className="row mb-1 mx-1">
                            {answer.evaluationAnswers.map((a, i) =>
                                <div className="border mb-1" key={i}>
                                    <span
                                        onClick={() => {
                                            answer.evaluationAnswers.splice(i, 1);
                                            onAnswerTypeUpdated?.call(undefined, answer);
                                        }}
                                        className="btn btn-danger btn-sm m-1">
                                        <i className="bi bi-trash-fill fs-6" /> Delete Question
                                    </span>
                                    <div className="mb-1 py-1">
                                        <label className="form-label" htmlFor="answerInput">Answer Text:</label>
                                        <input
                                            type={'text'}
                                            id="answerInput"
                                            className='form-control mb-1'
                                            onChange={(e) => { a.answer = e.target.value; onAnswerUpdated?.call(undefined, i, a); }}
                                            value={a.answer} />
                                        <RequiredValidator group={group} value={a.answer} />
                                        <div className="row align-items-center">
                                            <div className="col-md-6">
                                                <label className="form-label" htmlFor="multiplierInput">Multiplier: </label>
                                                <input
                                                    type={'number'}
                                                    id="multiplierInput"
                                                    className='form-control'
                                                    onChange={(e) => {
                                                        if (!Number.isNaN(e.target.valueAsNumber))
                                                            a.multiplier = e.target.valueAsNumber;
                                                        onAnswerUpdated?.call(undefined, i, a);
                                                    }}
                                                    value={a.multiplier} />
                                                <RangeValidator min={0} group={group} value={a.multiplier} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" htmlFor="isNeutralInput">Neutral: </label>
                                                <input
                                                    type={'checkbox'}
                                                    id="isNeutralInput"
                                                    className='form-check-input'
                                                    onChange={(e) => { a.isNeutral = e.target.checked; onAnswerUpdated?.call(undefined, i, a); }}
                                                    checked={a.isNeutral} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
        </EvalEditorSidebar >
    );
};