import { FunctionComponent, useEffect, useState } from "react";
import { ValidationContext, ValidationGroup } from "../types/validation";

export const Validator: FunctionComponent<{
    value: any,
    onValidate: (value: any) => boolean,
    message: string,
    group: ValidationGroup,
}> =
    ({ value, onValidate, message, group }) => {
        const [isValid, setIsValid] = useState<boolean>(true);

        useEffect(() => {
            const callback = (ctx: ValidationContext) => {
                if (onValidate(value) === false) {
                    ctx.makeInvalid();
                    setIsValid(false);
                } else {
                    setIsValid(true);
                }
            };

            group.addValidator(callback);

            return () => {
                group.removeValidator(callback);
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value]);

        return (
            <>
                {!isValid &&
                    <div className="text-danger fst-italic fw-semibold fs-6 w-100">{message}</div>
                }
            </>
        )
    };

export const RequiredValidator: FunctionComponent<{
    value: any,
    group: ValidationGroup
}> = ({ value, group }) => {
    const requiredValidate = (v: any) => {
        if (typeof v === 'string') {
            return v !== undefined && (v as string).replaceAll(' ', '').length !== 0;
        } else {
            return v !== undefined && v !== null;
        }
    };

    return (
        <Validator value={value} group={group} onValidate={requiredValidate} message="This field is required." />
    )
};

export const RangeValidator: FunctionComponent<{
    min?: number | bigint,
    max?: number | bigint,
    value: any,
    group: ValidationGroup
}> = ({ min, max, value, group }) => {
    const requiredValidate = (v: any) => {
        if (typeof v === 'string') {
            return ((min === undefined || v.trim().length >= min) && (max === undefined || v.trim().length <= max)) || value === undefined;
        } else if (typeof v === 'number' || typeof v === 'bigint') {
            return ((min === undefined || v >= min) && (max === undefined || v <= max)) || value === undefined;
        } else {
            return true;
        }
    };

    return (
        <Validator value={value} group={group} onValidate={requiredValidate} message={
            `Value out of range. ${min !== undefined ? 'Minimum: ' + min : ''} ${max !== undefined ? 'Maximum: (' + max + ')' : ''}`
        } />
    )
};