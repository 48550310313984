import notify from "devextreme/ui/notify";
import { useEffect, useState } from "react";
import { EvalEditorActions } from "../../components/eval-editor/EvalEditorActions";
import { EvalEditorAnswerDetails } from "../../components/eval-editor/EvalEditorAnswerDetails";
import { FullSpinner } from "../../components/spinner/Spinner";
import { useMsalToken } from "../../hooks/useToken";
import { EvalAnswer, EvalAnswerType } from "../../models/evaluation";
import { GetAnswersAsync } from "../../services/http-service";

export const EvalEditorAnswers = () => {
    const [answers, setAnswers] = useState<EvalAnswerType[]>();
    const [editingAnswer, setEditingAnswer] = useState<number>();
    // const token = useToken();
    const [isLoading, setIsLoading] = useState(false);

    const token = useMsalToken();

    useEffect(() => {
        if (!token) return;
        setIsLoading(true);
        GetAnswersAsync(token)
            .then(d => {
                if (d.ok) {
                    setAnswers(d.data);
                } else {
                    notify('There was an error while fetching the answers.', 'error');
                }
                setIsLoading(false);
            });
    }, [token]);

    const updateEditingAnswerType = (a: EvalAnswerType) => {
        if (editingAnswer === undefined || answers === undefined) return;
        answers[editingAnswer] = a;
        setAnswers([...answers]);
    }

    const updateEditingAnswer = (i: number, a: EvalAnswer) => {
        if (editingAnswer === undefined || answers === undefined) return;
        answers[editingAnswer].evaluationAnswers[i] = a;
        setAnswers([...answers]);
    }

    return (
        <>
            {isLoading && <FullSpinner />}
            {answers &&
                <div className='container ' style={{ maxWidth: '100%', overflow: 'hidden' }}>
                    <div className="row user-select-none">
                        <div className='col col-sm-2'>
                            <EvalEditorActions
                                actions={[
                                    { name: 'Add Answer Type', callback: () => { setAnswers([...answers!, { name: "new answer", evaluationAnswers: [], uid: undefined }]); } },
                                ]}
                            />
                        </div>
                        <div className='col border-bottom' style={{ height: '92vh', overflowY: 'auto' }}>
                            {answers.length > 0 &&
                                <div className='mt-1'>
                                    {answers.map((q, i) =>
                                        <div key={i} className="row justify-content-md-center mb-1 align-items-center border">
                                            <div className="col col-sm-1">
                                                {i}
                                            </div>
                                            <div className="col" style={{ wordBreak: 'break-all' }}>
                                                <div>{q.name}</div>
                                            </div>
                                            <div className="col col-sm-1"><i onClick={() => setEditingAnswer(i)} className="btn bi bi-pencil-fill" /></div>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                        <div className='col mx-2'>
                            <EvalEditorAnswerDetails
                                answer={editingAnswer !== undefined ? answers[editingAnswer] : undefined}
                                onAnswerTypeUpdated={updateEditingAnswerType}
                                onAnswerUpdated={updateEditingAnswer}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    );
};