import notify from 'devextreme/ui/notify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EvalFillQuestion, EvalFillHeader, EvalFillFinish } from '../../components/eval-fill/EvalFillRow';
import { FullSpinner } from '../../components/spinner/Spinner';
import { LIST_URI } from '../../constants';
import { useMsalToken } from '../../hooks/useToken';
import { useValidationGroup } from '../../hooks/useValidation';
import { AnswersForScoring, Eval, EvalAnswer, EvalAnswerType, EvalQuestion, EvalServiceItem } from '../../models/evaluation';
import { GetAnswersForEvalAsync, GetEmployee, GetEvaluationWithUidAsync, GetResultWithIdAsync, SendAnswersForScoring } from '../../services/http-service';
import './EvalFill.scss'
import { Employee } from '../../models/employee';

export const EvalFill = () => {
    const token = useMsalToken();
    const navigate = useNavigate();
    const validationGroup = useValidationGroup();

    const [evaluation, setEvaluation] = useState<Eval>();
    const [currentSection, setCurrentSection] = useState(0);
    const [employeeId, setEmployeeId] = useState<number>();
    const [answerTypes, setAnswerTypes] = useState<Map<string, EvalAnswerType>>();
    const [answers, setAnswers] = useState<Map<string, EvalAnswer>>();
    const [questions, setQuestions] = useState<Map<string, EvalQuestion>>();
    const [answeredEval, setAnsweredEval] = useState(new Map<string, string | undefined>());
    const [evalNotes, setEvalNotes] = useState(new Map<string, string | undefined>());
    const [isLoading, setIsLoading] = useState(false);
    const [showFinishScreen, setShowFinishScreen] = useState(false);
    const [notes, setNotes] = useState<string>();
    const [serviceItem, setServiceItem] = useState<EvalServiceItem>();
    const [employeeData, setEmployeeData] = useState<Employee>();

    useEffect(() => {
        if (!token) return;

        let params = new URLSearchParams(window.location.search);
        let evalId = params.get('eval');
        let employee = params.get('employee');
        let results = params.get('results');
        let version = Number.parseInt(params.get('version') ?? '0');

        if (employee === null || Number.isNaN(employee))
            return;

        const eid = Number.parseInt(employee)
        setEmployeeId(eid);

        GetEmployee(eid, token)
            .then(r => {
                if (r.data) {
                    setEmployeeData(r.data);
                }
            });

        if (evalId && version) {
            setIsLoading(true);
            GetEvaluationWithUidAsync(token, evalId, version)
                .then(d => {
                    if (d.ok) {
                        setEvaluation(d.data);
                        setQuestions(new Map(d.data?.evaluationQuestionSections.flatMap(s => s.evaluationQuestions).map(q => [q.uid, q] as [string, EvalQuestion])))
                    } else {
                        notify(`There was an error while fetching the evaluation form: ${d.error?.message}`, "error");
                    }
                }).then(() => {
                    if (!evalId) throw new Error("Eval id is not defined");
                    GetAnswersForEvalAsync(token, evalId, version)
                        .then(d => {
                            if (d.ok) {
                                let at = d.data?.map(a => [a.uid!, a] as [string, EvalAnswerType]);
                                let a = d.data?.flatMap(a => a.evaluationAnswers).map(a => [a.uid!, a] as [string, EvalAnswer]);
                                setAnswerTypes(new Map<string, EvalAnswerType>(at));
                                setAnswers(new Map<string, EvalAnswer>(a));
                            } else {
                                notify(`There was an error while fetching the evaluation answers: ${d.error?.message}`, "error");
                            }
                            if (results) {
                                GetResultWithIdAsync(Number.parseInt(results), token)
                                    .then(d => {
                                        if (d.ok) {
                                            let ans = d.data?.evaluationResultAnswers.map(a => [a.question.uid, a.answer.uid] as [string, string | undefined]);
                                            let notes = d.data?.evaluationResultAnswers.map(a => [a.question.uid, a.notes] as [string, string | undefined]);
                                            setAnsweredEval(new Map(ans));
                                            setEvalNotes(new Map(notes))
                                            setNotes(d.data?.notes);
                                            setServiceItem(d.data?.serviceItem)
                                        } else {
                                            notify(`There was an error while fetching the evaluation answers: ${d.error?.message}`, "error");
                                        }
                                        setIsLoading(false);
                                    });
                            }
                            else {
                                setIsLoading(false);
                            }
                        });
                });
        }
    }, [token]);

    const setAnswer = (q: EvalQuestion, a?: string) => {
        const answer = a ? answers?.get(a) : undefined;
        answeredEval.set(q.uid!, answer?.uid);
        setAnsweredEval(new Map(answeredEval));
    }

    const setNote = (q: EvalQuestion, a?: string) => {
        evalNotes.set(q.uid!, a);
        setEvalNotes(new Map(evalNotes));
    }

    const advanceSection = (n: number) => {
        if (n > 0 && currentSection < evaluation?.evaluationQuestionSections.length! - 1)
            setCurrentSection(currentSection + 1);
        else if (n < 0 && currentSection > 0)
            setCurrentSection(currentSection - 1);
    };

    const finish = (serviceItem?: EvalServiceItem, notes?: string) => {
        if (!token || !validationGroup.causeValidation()) return;

        let params = new URLSearchParams(window.location.search);
        let results = params.get('results');

        const answersForScoring: AnswersForScoring = {
            evaluationUid: evaluation?.uid!,
            evaluationVersion: evaluation?.version!,
            employeeId: employeeId!,
            notes: notes,
            serviceItemId: serviceItem?.id,
            answers: [],
            resultsId: results ? Number.parseInt(results!) : undefined,
        };
        answeredEval.forEach((v, k) => {
            const answer = answers?.get(v!);
            const question = questions?.get(k!);
            const note = evalNotes?.get(k!);
            answersForScoring.answers.push({
                answerUid: answer?.uid!,
                answerVersion: answer?.version!,
                questionUid: question?.uid!,
                questionVersion: question?.version!,
                notes: note
            });
        });
        setIsLoading(true);
        SendAnswersForScoring(answersForScoring, token)
            .then(d => {
                setIsLoading(false);
                if (d.ok) {
                    navigate(`${LIST_URI}`);
                    notify("Evaluation has been submitted successfully.", "success");
                } else {
                    notify(`There was an error while submitting the evaluation answers: ${d.error?.message}`, "error");
                }
            }).catch(e => {
                console.error(e);
                setIsLoading(false)
            });
    };

    return <>
        {isLoading && <FullSpinner />}
        {(!evaluation || !answerTypes || employeeId === undefined) && !isLoading &&
            <div className="container text-center">
                <div className='fs-1 mt-5 fw-bold border rounded-4 bg-white'>Select an evaluation from the list. </div>
            </div>
        }
        {evaluation && answerTypes && employeeId !== undefined &&
            <div className='container bg-fill' style={{ maxWidth: '100%', height: 'calc(100vh - 51px)', overflow: 'auto' }}>
                <div className="progress mt-1">
                    <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        style={{ width: `${((currentSection + 1) / evaluation.evaluationQuestionSections.length) * 100}%` }} />
                </div>
                <div className='mb-1'>
                    {evaluation !== undefined && currentSection !== undefined &&
                        <>
                            <EvalFillHeader
                                section={evaluation.evaluationQuestionSections[currentSection]}
                                employee={employeeData}
                                onPrevious={currentSection > 0 ? () => advanceSection(-1) : undefined}
                                onNext={currentSection < evaluation?.evaluationQuestionSections.length! - 1 ? () => { if (validationGroup.causeValidation()) advanceSection(+1); } : undefined}
                                onFinish={currentSection === evaluation?.evaluationQuestionSections.length! - 1 ? () => { if (validationGroup.causeValidation()) setShowFinishScreen(true); } : undefined} />
                            {evaluation.evaluationQuestionSections[currentSection].evaluationQuestions.map((q, i) => {
                                return <EvalFillQuestion
                                    key={i.toString() + currentSection.toString()}
                                    index={i}
                                    question={q}
                                    selectedAnswer={answeredEval}
                                    onAnswerChanged={(a) => setAnswer(q, a)}
                                    answers={answerTypes?.get(q.answerTypeUid!)!.evaluationAnswers!}
                                    validationGroup={validationGroup}
                                    note={evalNotes.get(q.uid!)}
                                    onNoteChanged={(a) => setNote(q, a)}
                                />

                            })}
                            <EvalFillFinish
                                initNotes={notes}
                                initServiceItem={serviceItem}
                                visible={showFinishScreen}
                                onSubmit={(s, n) => {
                                    if (validationGroup.causeValidation()) finish(s, n);
                                }}
                                evalUid={evaluation.uid!}
                                evalVersion={evaluation.version!} />
                        </>
                    }
                </div>
            </div>
        }
    </>
};