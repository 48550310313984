import { InteractionRequiredAuthError, IPublicClientApplication } from "@azure/msal-browser";
import { useEffect, useState } from "react";
import { AZURE_SCOPES } from "../services/auth-config";

/**
 * A hook that logs in the user silently if possible
 */
export const useSilentLogin = (instance: IPublicClientApplication) => {
    const [username, setUsername] = useState<string>();

    useEffect(() => {
        if (username === undefined) {
            const currentAccounts = instance.getAllAccounts();
            if (currentAccounts.length === 0) {
                instance.ssoSilent({ scopes: AZURE_SCOPES }).then(
                    r => setUsername(r.account?.username)
                )
                    .catch(e => {
                        if (e instanceof InteractionRequiredAuthError) {
                            console.warn("No user is logged in.");
                        } else {
                            console.error(e.message);
                        }
                    });
                return;
            } else if (currentAccounts.length > 1) {
                // Add choose account code here
                console.warn("Multiple accounts detected.");
            } else if (currentAccounts.length === 1) {
                setUsername(currentAccounts[0].username);
            }
        }
    }, [username, instance]);

    return username;
};