import EventEmitter from "events";
import { useEffect, useState } from "react";
import { ValidationCallback } from "../types/validation";

class ValidationContextImpl {
    isValid: boolean;

    constructor() {
        this.isValid = true;
    }

    makeInvalid() {
        this.isValid = false;
    }
}

export const useValidationGroup = () => {
    const [emitter, setEmitter] = useState(new EventEmitter());

    useEffect(() => {
        emitter.setMaxListeners(100);
    });

    return {
        addValidator: (v: ValidationCallback) => {
            const e = emitter.addListener('onvalidate', v);
            setEmitter(e);
        },
        removeValidator: (v: ValidationCallback) => {
            const e = emitter.removeListener('onvalidate', v)
            setEmitter(e);
        },
        causeValidation: () => {
            const ctx = new ValidationContextImpl();
            emitter.emit('onvalidate', ctx);
            return ctx.isValid;
        }
    };
};