import { createRef, FunctionComponent, useEffect, useState } from "react";
import { EvalQuestion, EvalSection } from "../../models/evaluation";
import { ValidationGroup } from "../../types/validation";
import { RequiredValidator } from "../Validator";

export const EvalEditorEditButtons: FunctionComponent<{
    onReorder: (a: number) => void,
    onEdit: () => void,
    onDelete: () => void
}> = ({ onReorder, onEdit, onDelete }) => {
    return (
        <>
            <div className="col col-sm-1"><i onClick={(_) => onReorder(-1)} className="btn bi bi-arrow-up-square-fill" /></div>
            <div className="col col-sm-1"><i onClick={(_) => onReorder(+1)} className="btn bi bi-arrow-down-square-fill" /></div>
            <div className="col col-sm-1"><i onClick={onEdit} className="btn bi bi-pencil-fill" /></div>
            <div className="col col-sm-1"><i onClick={onDelete} className="btn bi bi-trash-fill" /></div>
        </>
    );
}

export const EvalEditorSection: FunctionComponent<{
    onSelect: () => void,
    isSelected: boolean,
    index: number,
    section: EvalSection,
    reorder: (inc: number) => void,
    remove: () => void,
    onValueChanged: (value: string) => void,
    validationGroup: ValidationGroup,
}> = ({ onSelect, isSelected, index, section, reorder, remove, onValueChanged, validationGroup }) => {
    let [isEditing, setIsEditing] = useState<boolean>();

    useEffect(() => {
        sectionInputRef?.current?.select();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing])

    let sectionInputRef = createRef<HTMLInputElement>();

    return (
        <div
            className={`row justify-content-md-center mb-1 align-items-center fs-6 fw-semibold
                                    border ${isSelected ? 'bg-orange' : 'bg-light'} eval-section`}
            onClick={onSelect}>
            <div className="col col-sm-1">
                {index}
            </div>
            <div className="col text-center" >
                {!isEditing &&
                    <div>{section.sectionName}</div>
                }
                <RequiredValidator value={section.sectionName} group={validationGroup} />
                {isEditing &&
                    <>
                        <input
                            ref={sectionInputRef}
                            onChange={(e) => onValueChanged(e.target.value)}
                            onKeyDown={(e) => { if (e.key === 'Enter' && validationGroup.causeValidation()) setIsEditing(false); }}
                            type={'text'}
                            value={section.sectionName}
                            onBlur={() => { if (validationGroup.causeValidation()) setIsEditing(false); }} />
                    </>
                }
            </div>
            <EvalEditorEditButtons
                onReorder={reorder}
                onEdit={() => setIsEditing(true)}
                onDelete={remove}
            />
        </div>
    )
};

export const EvalEditorQuestion: FunctionComponent<{
    index: number,
    question: EvalQuestion,
    reorder: (inc: number) => void,
    remove: () => void,
    onEdit: () => void,
}> = ({ index, question, reorder, remove, onEdit }) => {
    return (
        <div className="row justify-content-md-center mb-1 align-items-center border">
            <div className="col col-sm-1">
                {index}
            </div>
            <div className="col" >
                <div>{question.question}</div>
            </div>
            <EvalEditorEditButtons
                onReorder={reorder}
                onEdit={onEdit}
                onDelete={remove}
            />
        </div>
    )
};