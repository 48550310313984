import { SelectBox } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { FunctionComponent, useEffect, useState } from "react";
import { useMsalToken } from "../../hooks/useToken";
import { Company } from "../../models/company";
import { GetCompaniesAsync } from "../../services/http-service";
import './CompaniesDropdown.css';

const getDefaultCompany = () => JSON.parse(sessionStorage.getItem("company") ?? "null") as (number | undefined);

export interface CompaniesDropdownProps {
    onSelectedCompanyChanged: (c: Company | undefined) => void;
}

const cachedCompanies = new class {
    companies: Map<number, Company> = new Map();
}();

const CompaniesDropdown: FunctionComponent<CompaniesDropdownProps> = ({ onSelectedCompanyChanged }) => {
    const token = useMsalToken();
    const [companies, setCompanies] = useState<Map<number, Company>>(cachedCompanies.companies);
    const [selectedCompany, setSelectedCompany] = useState(getDefaultCompany());

    useEffect(() => {
        if (!token || (companies && companies?.size > 0)) return;
        GetCompaniesAsync(token).then(d => {
            if (d.ok && d.data) {
                const m = new Map(d.data.map(c => [c.id, c] as [number, Company]));
                setCompanies(m);
                cachedCompanies.companies = m;
            } else {
                notify(`There was an error: ${d.error?.message}`, 'error', 2300);
            }
        });
    }, [token, companies]);

    return (
        <>
            <SelectBox
                dataSource={Array.from(companies.values())}
                displayExpr="companyName"
                valueExpr="id"
                noDataText="Loading..."
                onValueChanged={(e) => {
                    setSelectedCompany(e.value);
                    sessionStorage.setItem("company", JSON.stringify(e.value));
                    const cid = e.value;
                    onSelectedCompanyChanged(cid !== undefined ? companies.get(cid) : undefined);
                }}
                stylingMode="filled"
                className="comp-dropdown"
                defaultValue={selectedCompany}
                onInitialized={(_) => {
                    const cid = getDefaultCompany();
                    onSelectedCompanyChanged(cid !== undefined ? companies.get(cid) : undefined);
                }}
            />
        </>
    )
};

export default CompaniesDropdown;