import { Configuration, PopupRequest } from "@azure/msal-browser";


export const AUTH_HEADER = "X-MELLON-AUTHORIZATION";

const AUTHORITY = process.env.REACT_APP_AUTHORITY;

export const MSAL_CONFIG: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: AUTHORITY,
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
        knownAuthorities: [AUTHORITY]
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
}

export const AZURE_SCOPES = ["User.Read", "email"];

export const loginRequest: PopupRequest = {
    scopes: AZURE_SCOPES,
};