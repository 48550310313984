import { useEffect, useState } from "react";

export type DataListProps<T> = {
    data?: T[],
    onSelected?: (d: T, i: number) => void,
    display?: (d: T) => string,
    selectionIndex?: number,
    searchBar?: {
        show: boolean,
        filter: (data: T[], term?: string) => T[],
    },
};

export const DataList = <T extends unknown>({ data, onSelected, display, selectionIndex, searchBar }: DataListProps<T>) => {
    const [selectedIndex, setSelectedIndex] = useState(selectionIndex);
    const [searchTerm, setSearchTerm] = useState<string>();
    const [filteredData, setFilteredData] = useState<T[] | undefined>();

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    return (
        <>
            {searchBar?.show &&
                <input placeholder="Search" type="text" className="form-control rounded-0" value={searchTerm ?? ''} onChange={(e) => {
                    setSearchTerm(e.currentTarget.value);
                    if (data) setFilteredData(searchBar?.filter?.call(undefined, data, e.currentTarget.value));
                }} />
            }
            <div className="list-group rounded-0" style={{ maxHeight: '100%', overflowY: 'auto' }}>
                {filteredData && filteredData.map((s, i) =>
                    <a
                        key={i}
                        href="/"
                        className={`list-group-item list-group-item-action ${selectedIndex === i ? 'active' : ''}`}
                        onClick={(e) => { setSelectedIndex(i); e.preventDefault(); onSelected?.call(undefined, s, i); }}>
                        {display?.call(undefined, s) ?? i.toString()}
                    </a>
                )}
            </div>
        </>
    )
};