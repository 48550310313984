import notify from "devextreme/ui/notify";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CompaniesDropdown from "../../components/companies-dropdown/CompaniesDropdown";
import { DataList } from "../../components/data-list/DataList";
import { FullSpinner } from "../../components/spinner/Spinner";
import { RESULTS_URI } from "../../constants";
import { useMsalToken } from "../../hooks/useToken";
import { Company } from "../../models/company";
import { Employee } from "../../models/employee";
import { EvalResult } from "../../models/evaluation";
import { Team } from "../../models/team";
import { GetCompanyEmployeesAsync, GetCompanyTeamsAsync, GetEvaluatorsAsync, GetResultsForCompanyAsync, GetResultsForEmployeeAsync, GetResultsForEvaluatorAsync, GetResultsForTeamAsync } from "../../services/http-service";
import { ScoredResultsGrid, DetailedResultsGrid } from "./EvalResultGrids";
import { createPivotGridDataSource, ResultsMode } from "./EvalResultsData";
import { Evaluator } from "../../models/user";

export const EvalResults = () => {
    const token = useMsalToken();
    const navigate = useNavigate();

    const [selectedCompany, setSelectedCompany] = useState<Company>();
    const [resultsMode, setResultsMode] = useState<ResultsMode>();
    const [entities, setEntities] = useState<Employee[] | Team[] | Evaluator[]>();
    const [entityId, setEntityId] = useState<number>();

    const [results, setResults] = useState<EvalResult[]>();
    const [dataSource, setDataSource] = useState<PivotGridDataSource>();

    const [isLoading, setIsLoading] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('mode')) {
            const mode = params.get('mode') as ResultsMode | null;
            if (mode !== 'company' && mode !== 'employee' && mode !== 'team' && mode !== 'evaluator' && mode !== null) return;
            setResultsMode(mode ?? undefined);
            const idstr = params.get('id');
            const id = idstr ? Number.parseInt(idstr) : undefined;
            setEntityId(id);
            if (id === undefined) {
                setResults(undefined);
            }
        } else {
            setEntityId(undefined);
            setResultsMode(undefined);
            setResults(undefined);
        }
    });

    useEffect(() => {
        if (!token || !selectedCompany || !resultsMode) return;
        setIsLoading(true);
        if (resultsMode === 'employee') {
            GetCompanyEmployeesAsync(selectedCompany.id, token)
                .then(d => {
                    if (d.ok) {
                        setEntities(d.data);
                    } else {
                        notify(`There was an error while fetching the data: ${d.error?.message}`, "error");
                    }
                    setIsLoading(false);
                });
        } else if (resultsMode === 'team') {
            GetCompanyTeamsAsync(selectedCompany.id, token)
                .then(d => {
                    if (d.ok) {
                        setEntities(d.data);
                    } else {
                        notify(`There was an error while fetching the data: ${d.error?.message}`, "error");
                    }
                    setIsLoading(false);
                });
        } else if (resultsMode === 'evaluator') {
            GetEvaluatorsAsync(token, false)
                .then(d => {
                    if (d.ok) {
                        setEntities(d.data);
                    } else {
                        notify(`There was an error while fetching the data: ${d.error?.message}`, "error");
                    }
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [resultsMode, token, selectedCompany]);

    useEffect(() => {
        if (entityId === undefined || !token) return;
        setResults(undefined);
        setIsLoading(true);
        const f = () => {
            switch (resultsMode) {
                case 'company':
                    return GetResultsForCompanyAsync(entityId, token);
                case 'team':
                    return GetResultsForTeamAsync(entityId, token);
                case 'employee':
                    return GetResultsForEmployeeAsync(entityId, token);
                case 'evaluator':
                    return GetResultsForEvaluatorAsync(entityId, token);
            }
        };

        f()?.then((d) => {
            if (d.ok) {
                setResults(d.data);
                setDataSource(createPivotGridDataSource(d.data!, resultsMode));
            } else {
                notify(`There was an error while fetching the results: ${d.error?.message}`, "error");
            }
            setIsLoading(false);
        });
    }, [entityId, token, resultsMode]);

    return (
        <>
            {isLoading && <FullSpinner />}
            <div className="mx-4 full-height" style={{ overflow: 'auto' }}>
                {/* Step one: Select a company */}
                {entityId === undefined &&
                    <div className="d-flex justify-content-center mt-2">
                        <div className="w-25">
                            <CompaniesDropdown onSelectedCompanyChanged={(c) => { setSelectedCompany(c); setResultsMode(undefined); }} />
                        </div>
                    </div>
                }
                {/* Step two: Select a search result mode */}
                {!results && selectedCompany && entityId === undefined &&
                    <div className="mt-2">
                        <span className="text-center fw-bold fs-4"><div>Search results by:</div></span>
                        <div className="container d-flex justify-content-around mt-4">
                            <button className="btn btn-secondary" onClick={() => navigate(`${RESULTS_URI}?mode=company&id=${selectedCompany.id}`)}>Company</button>
                            <button className="btn btn-secondary" onClick={() => navigate(`${RESULTS_URI}?mode=team`)}>Team</button>
                            <button className="btn btn-secondary" onClick={() => navigate(`${RESULTS_URI}?mode=employee`)}>Employee</button>
                            <button className="btn btn-secondary" onClick={() => navigate(`${RESULTS_URI}?mode=evaluator`)}>Evaluator</button>
                        </div>
                    </div>
                }
                {/* Step three: Select an entity from the specified mode */}
                {resultsMode === 'team' && entities && entityId === undefined &&
                    <div className="d-flex justify-content-center mt-2">
                        <div className="w-50">
                            <DataList
                                searchBar={{ show: true, filter: (d, s) => d.filter(e => !s || e.name.includes(s)) }}
                                data={entities as any[]} display={(d) => d.name}
                                onSelected={(e: Team) => e.id && navigate(`${RESULTS_URI}?mode=team&id=${e.id}`)} />
                        </div>
                    </div>
                }
                {resultsMode === 'employee' && entities && entityId === undefined &&
                    <div className="w-50 m-auto mt-2">
                        <DataList
                            searchBar={{
                                show: true, filter: (d, s) => d.filter(e => !s || e.employeeId.includes(s) || e.firstName.includes(s)
                                    || e.lastName.includes(s))
                            }}
                            data={entities as any[]} display={(d) => `${d.employeeId} - ${d.firstName} - ${d.lastName}`}
                            onSelected={(e: Employee) => e.id && navigate(`${RESULTS_URI}?mode=employee&id=${e.id}`)} />
                    </div>
                }
                {resultsMode === 'evaluator' && entities && entityId === undefined &&
                    <div className="w-50 m-auto mt-2">
                        <DataList
                            searchBar={{
                                show: true, filter: (d, s) => d.filter(e => !s || e.email.includes(s))
                            }}
                            data={entities as Evaluator[]} display={(d) => `${d.email}`}
                            onSelected={(e: Evaluator) => navigate(`${RESULTS_URI}?mode=evaluator&id=${e.id}`)} />
                    </div>
                }
                {entityId !== undefined && dataSource &&
                    <div>
                        <button
                            className="btn btn-primary m-2 text-white"
                            onClick={() => { navigate(RESULTS_URI); setSelectedCompany(undefined); setResults(undefined); setEntityId(undefined); setResultsMode(undefined); }}>
                            <i className="bi bi-box-arrow-in-left"></i> Back
                        </button>
                        {resultsMode === 'company' &&
                            <ScoredResultsGrid dataSource={dataSource} />
                        }
                        {resultsMode === 'employee' && results &&
                            <DetailedResultsGrid dataSource={results} />
                        }
                        {resultsMode === 'team' && results &&
                            <DetailedResultsGrid dataSource={results} showDetailsReportDownload={true} />
                        }
                        {resultsMode === 'evaluator' && results &&
                            <DetailedResultsGrid dataSource={results} />
                        }
                    </div>
                }
            </div>
        </>
    );
};