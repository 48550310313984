import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EvalEditorActions } from '../../components/eval-editor/EvalEditorActions';
import { EvalEditorDetails } from '../../components/eval-editor/EvalEditorDetails';
import { EvalEditorQuestion, EvalEditorSection } from '../../components/eval-editor/EvalEditorRow';
import { EDITOR_URI } from '../../constants';
import { Eval, EvalAnswerType, EvalQuestion } from '../../models/evaluation';
import './EvalEditor.scss'
import { confirm } from 'devextreme/ui/dialog'
import { useValidationGroup } from '../../hooks/useValidation';
import { GetAnswersAsync, GetEvaluationWithUidAsync, InsertOrUpdateEvalAsync } from '../../services/http-service';
import notify from 'devextreme/ui/notify';
import { FullSpinner } from '../../components/spinner/Spinner';
import { RequiredValidator } from '../../components/Validator';
import { useMsalToken } from '../../hooks/useToken';
import { ServiceItems } from '../../components/ServiceItems';

const MAX_QUESTIONS = 30;
const MAX_SECTIONS = 15;

export const EvalEditor = () => {
    const token = useMsalToken();
    const navigate = useNavigate();
    const validationGroup = useValidationGroup();

    const [evaluation, setEvaluation] = useState<Eval>();
    const [answerTypes, setAnswerTypes] = useState<EvalAnswerType[]>();
    const [teamId, setTeamId] = useState<number>();
    const [currentSection, setCurrentSection] = useState<number>(0);
    const [currentQuestion, setCurrentQuestion] = useState<number>();
    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showServiceItems, setShowServiceItems] = useState(false);
    const getCurrentSection = () => currentSection !== undefined ? evaluation?.evaluationQuestionSections[currentSection] : undefined;

    useEffect(() => {
        validationGroup.causeValidation();
    }, [evaluation, validationGroup]);

    useEffect(() => {
        if (!token) return;
        GetAnswersAsync(token)
            .then(d => {
                if (d.ok) {
                    setAnswerTypes(d.data);
                } else {
                    console.log(d);
                }
            });

        const params = new URLSearchParams(window.location.search);
        const evalId = params.get('eval');
        const version = Number.parseInt(params.get('version') ?? '0');
        const teamId = params.get('team');

        if (teamId !== null)
            setTeamId(Number.parseInt(teamId));

        if (evalId && version) {
            setIsLoading(true);
            GetEvaluationWithUidAsync(token, evalId, version)
                .then(d => {
                    if (d.ok) {
                        setEvaluation(d.data);
                    } else {
                        console.log(d);
                    }
                    setIsLoading(false);
                })
        } else {
            if (teamId !== null)
                setEvaluation({ name: 'New Evaluation', evaluationQuestionSections: [{ sectionName: 'New Section', evaluationQuestions: [], order: 0 }] });
        }
    }, [token]);

    const addSection = () => {
        if (evaluation === undefined) return;

        if (evaluation.evaluationQuestionSections.length >= MAX_SECTIONS) {
            notify(`Cannot add more than ${MAX_SECTIONS} sections.`, "error");
            return;
        }

        evaluation.evaluationQuestionSections.push({ sectionName: 'New Section', evaluationQuestions: [], order: evaluation.evaluationQuestionSections.length - 1 });
        setCurrentSection(evaluation.evaluationQuestionSections.length - 1);
        setEvaluation({ ...evaluation });
        setIsDirty(true);
    }

    const addQuestion = () => {
        if (evaluation === undefined) return;

        let section = getCurrentSection();

        if (section === undefined) return;

        if (section.evaluationQuestions.length >= MAX_QUESTIONS) {
            notify(`Cannot add more than ${MAX_QUESTIONS} questions.`, "error");
            return;
        }

        section.evaluationQuestions.push({ question: "New Question", order: section.evaluationQuestions.length, score: 0 });
        setEvaluation({ ...evaluation });
        setIsDirty(true);
    };

    const editQuestion = (i: number, si: number) => {
        setCurrentSection(si);
        setCurrentQuestion(i);
        setIsDirty(true);
    };

    const updateQuestion = (i: number, q: EvalQuestion) => {
        if (evaluation === undefined) return;
        let section = getCurrentSection();
        if (section === undefined) return;
        section.evaluationQuestions[i] = q;
        setEvaluation({ ...evaluation });
        setIsDirty(true);
    };

    const removeQuestion = (i: number) => {
        if (evaluation === undefined) return;
        let section = getCurrentSection();
        if (section === undefined) return;
        section.evaluationQuestions.splice(i, 1);
        setEvaluation({ ...evaluation });
        setIsDirty(true);
    };

    const reorderQuestion = (i: number, a: number) => {
        if (evaluation === undefined) return;
        let section = getCurrentSection();
        if (section === undefined) return;
        let questions = section.evaluationQuestions;
        // Avoid overflows
        if ((i === 0 && a < 0) || (i === questions.length - 1 && a > 0)) return;
        let to = i + a; // The target index
        // Update both questions' order
        questions[i].order = to;
        questions[to].order = i;
        questions.splice(to, 0, questions.splice(i, 1)[0]); // Update positions
        setEvaluation({ ...evaluation });
        if (currentQuestion === i) setCurrentQuestion(to);
        else if (currentQuestion === to) setCurrentQuestion(i);
        setIsDirty(true);
    }

    const removeSection = (si: number) => {
        if (evaluation === undefined) return;
        evaluation.evaluationQuestionSections.splice(si, 1);
        if (evaluation.evaluationQuestionSections.length === 0) addSection();
        setEvaluation({ ...evaluation });
        setIsDirty(true);
    };

    const updateSection = (si: number, value: string) => {
        if (evaluation === undefined) return;
        evaluation.evaluationQuestionSections[currentSection].sectionName = value;
        setEvaluation({ ...evaluation });
        setIsDirty(true);
    }

    const reorderSection = (i: number, a: number) => {
        if (evaluation === undefined) return;
        let section = getCurrentSection();
        if (section === undefined) return;
        // Avoid overflows
        if ((i === 0 && a < 0) || (i === evaluation.evaluationQuestionSections.length - 1 && a > 0)) return;
        let to = i + a; // The target index
        // Update both sections' order
        evaluation.evaluationQuestionSections[i].order = to;
        evaluation.evaluationQuestionSections[to].order = i;
        // Update positions
        evaluation.evaluationQuestionSections.splice(to, 0, evaluation.evaluationQuestionSections.splice(i, 1)[0]);
        setEvaluation({ ...evaluation });
        if (currentSection === i) setCurrentQuestion(to);
        else if (currentSection === to) setCurrentQuestion(i);
        setIsDirty(true);
    }

    const saveEval = () => {
        if (!evaluation || !validationGroup.causeValidation() || !teamId || !token) return;
        setIsLoading(true);
        let validationErrors: { section: number, question: number } | undefined;

        evaluation.teamId = teamId;

        evaluation.evaluationQuestionSections.forEach((s, si) => {
            s.evaluationQuestions.forEach((q, i) => {
                if (q.answerTypeUid === undefined || q.answerTypeVersion === undefined || q.score === undefined ||
                    q.question === undefined || q.question.length === 0) {
                    validationErrors = { section: si, question: i }
                }
            });
        });

        if (validationErrors === undefined) {
            InsertOrUpdateEvalAsync(token, evaluation, evaluation?.uid === undefined)
                .then(d => {
                    if (d.ok) {
                        setEvaluation(d.data);
                        setIsDirty(false);
                        notify('The changes were saved successfully', 'success', 1500);

                        const params = new URLSearchParams(window.location.search);
                        if (Number.parseInt(params.get('version') ?? '0') !== d.data?.version) {
                            params.set('version', d.data?.version!.toString()!);
                        }
                        if (d.data && d.data.uid && params.get('eval') !== d.data?.uid) {
                            params.set('eval', d.data.uid);
                        }
                        navigate({ search: params.toString() });
                    } else {
                        notify(`There was an error while saving the changes: ${d.error?.message}`, 'error', 2300);
                    }
                    setIsLoading(false);
                });
        } else {
            setCurrentSection(validationErrors.section);
            setCurrentQuestion(validationErrors.question);
            setIsLoading(false);
        }
    }

    return (
        <>
            {evaluation?.uid && evaluation?.version &&
                <ServiceItems visible={showServiceItems} evalUid={evaluation.uid} evalVersion={evaluation.version} onVisibilityChange={(v) => setShowServiceItems(v)} />
            }
            {isLoading && <FullSpinner rem={3} />}
            {teamId === undefined && !evaluation && !isLoading &&
                <div className="container text-center">
                    <div className='fs-1 mt-5 fw-bold border rounded-4 bg-white'>Please select an evaluation or a team to create a new evaluation for. </div>
                </div>
            }
            {teamId !== undefined &&
                <div className="row user-select-none h-100 mx-0">
                    <div className='col col-sm-2'>
                        <div className="row">
                            <EvalEditorActions
                                actions={[
                                    {
                                        name: 'New Evaluation', callback: async () => {
                                            if (!isDirty || await confirm('Are you sure you want to discard the current evaluation changes?', 'Are you sure?')) {
                                                navigate(`${EDITOR_URI}?team=${evaluation?.teamId ?? teamId}`);
                                                setIsDirty(false);
                                                if (evaluation || teamId !== undefined)
                                                    setEvaluation({ name: 'New Evaluation', teamId: evaluation?.teamId ?? teamId, evaluationQuestionSections: [{ sectionName: 'New Section', evaluationQuestions: [], order: 0 }] });
                                                else
                                                    setEvaluation(undefined);
                                            }
                                        }
                                    },
                                    { name: 'Add Section', callback: addSection },
                                    { name: 'Add Question', callback: addQuestion },
                                    { name: 'Save', callback: saveEval },
                                    {
                                        name: 'Service Items', callback: () => {
                                            if (!evaluation?.uid) {
                                                notify("Save the questionnaire before adding service items.", "error");
                                            } else {
                                                setShowServiceItems(true);
                                            }
                                        }
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className='col-6 full-height' style={{ overflowY: 'auto' }}>
                        {!isLoading &&
                            <>
                                <div className="row">
                                    <label htmlFor="eval-name-input" className='form-label fw-semibold fs-6'>Evaluation Name</label>
                                    <input type="text" id='eval-name-input' className="form-control"
                                        value={evaluation?.name ?? ''}
                                        onChange={(e) => { if (evaluation) { evaluation.name = e.currentTarget.value; setEvaluation({ ...evaluation }); } }} />
                                    <RequiredValidator value={evaluation?.name} group={validationGroup} />
                                </div>
                                <div className="row">
                                    {evaluation?.evaluationQuestionSections.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)).map((s, si) =>
                                        <div key={si} className='mt-1 border p-3'>
                                            <EvalEditorSection
                                                key={si}
                                                onSelect={() => { setCurrentQuestion(undefined); setCurrentSection(si); }}
                                                isSelected={si === currentSection}
                                                index={si}
                                                section={s}
                                                reorder={(inc) => reorderSection(si, inc)}
                                                remove={() => removeSection(si)}
                                                onValueChanged={(v) => updateSection(si, v)}
                                                validationGroup={validationGroup} />
                                            <div className="mx-1">
                                                {s.evaluationQuestions.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)).map((q, i) =>
                                                    <EvalEditorQuestion
                                                        key={i}
                                                        index={i}
                                                        question={q}
                                                        reorder={(inc) => reorderQuestion(i, inc)}
                                                        remove={() => removeQuestion(i)}
                                                        onEdit={() => editQuestion(i, si)} />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        }
                    </div>
                    <div className='col'>
                        <EvalEditorDetails
                            question={currentQuestion !== undefined ? getCurrentSection()?.evaluationQuestions[currentQuestion] : undefined}
                            onQuestionUpdated={(q) => updateQuestion(currentQuestion as number, q)}
                            answerTypes={answerTypes} validationGroup={validationGroup}
                        />
                    </div>
                </div>
            }
        </>
    )
};