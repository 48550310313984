import { AccountInfo, AuthenticationResult, AuthenticationScheme, InteractionRequiredAuthError, InteractionStatus, InteractionType, IPublicClientApplication, PopupRequest } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { AZURE_SCOPES } from "../services/auth-config";

/**
 * A hook that gets a token for the user silently.
 * @returns A token for the user
 */
// export const useMsalToken = () => {
//     const auth = useMsalAuthentication(InteractionType.Silent);
//     const [token, setToken] = useState<string>();
//     const [expTimeout, setExpTimeout] = useState<NodeJS.Timeout>();

//     const updateToken = () => {
//         auth.acquireToken(InteractionType.Silent, { scopes: AZURE_SCOPES, authenticationScheme: AuthenticationScheme.BEARER })
//             .then(r => {
//                 setToken(r?.idToken);
//                 if (r?.idToken) {
//                     if (r.expiresOn) {
//                         const timeout = setTimeout(() => { updateToken(); }, r.expiresOn.getTime() - new Date().getTime());
//                         setExpTimeout(timeout);
//                     }
//                 }
//             });
//     };

//     useEffect(() => {
//         updateToken();
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);

//     useEffect(() => {
//         return () => clearTimeout(expTimeout)
//     }, [expTimeout]);

//     return token ? 'Bearer ' + token : undefined;
// }

export const useMsalToken = () => {
    const { instance, inProgress } = useMsal();
    const [authResult, setAuthResult] = useState<null | AuthenticationResult>(null);

    useEffect(() => {
        const getResult = () => {
            login(instance, { scopes: AZURE_SCOPES, authenticationScheme: AuthenticationScheme.BEARER }, inProgress).then((r) => {
                setAuthResult(r ?? null);
                if (r?.expiresOn) {
                    setTimeout(getResult, r.expiresOn.getTime() - new Date().getTime())
                }
            });

        };

        getResult();
    }, [inProgress, instance]);

    return authResult?.idToken ? `Bearer ` + authResult.idToken : undefined;
}

async function login(instance: IPublicClientApplication, request: PopupRequest, inProgress: InteractionStatus) {
    if (inProgress === InteractionStatus.None) {
        const account = instance.getActiveAccount();
        if (!account) {
            const result = await instance.acquireTokenPopup(request);
            return result;
        } else {
            try {
                const result = await instance.acquireTokenSilent({
                    ...request,
                    account,
                });
                return result;
            } catch (e) {
                console.error(e);
                if (e instanceof InteractionRequiredAuthError) {
                    const result = await instance.acquireTokenPopup({
                        ...request,
                        account: instance.getActiveAccount() as AccountInfo
                    });
                    return result;
                }
            }
        }
    }
}

export const useAuth = (loginRequest: PopupRequest) => {
    const { instance, inProgress } = useMsal();
    const [authResult, setAuthResult] = useState<null | AuthenticationResult>(null);

    useEffect(() => {
        const getResult = () => {
            login(instance, loginRequest, inProgress).then((r) => {
                setAuthResult(r ?? null);
                if (r?.expiresOn) {
                    setTimeout(getResult, r.expiresOn.getTime() - new Date().getTime())
                }
            });

        };

        getResult();
    }, [inProgress, instance, loginRequest]);

    return authResult?.idToken;
}