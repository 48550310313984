import { FunctionComponent, ReactNode } from "react";

export interface EvalEditorSidebarProps {
    children: ReactNode[] | ReactNode,
    title?: string, img?: string,
    className?: string
}

export const EvalEditorSidebar: FunctionComponent<EvalEditorSidebarProps> = ({ children, title, img, className }) => {
    return (
        <div className={`d-flex flex-column align-items-stretch flex-shrink-0 bg-white h-100 ${className ?? ''}`}>
            <div className='d-flex flex-shrink-0 text-decoration-none p-3 bg-light'>
                <span className='fs-4 fw-semibold'>
                    {img && <i className={`bi bi-${img}`} />} {title}
                </span>
            </div>
            {children}
        </div>
    );
};