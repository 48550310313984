import { DataGrid, Popup } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import { FunctionComponent, useEffect, useState } from "react";
import { useMsalToken } from "../hooks/useToken";
import { AddServiceItemAsync, GetServiceItemsForTeamAsync } from "../services/http-service";


export const ServiceItems: FunctionComponent<{
    evalUid: string,
    evalVersion: number,
    visible: boolean,
    onVisibilityChange: (value: boolean) => void,
}> = ({ evalUid, evalVersion, visible, onVisibilityChange }) => {
    let token = useMsalToken();
    const [store, setStore] = useState<CustomStore>();

    useEffect(() => {
        if (!token) return;
        setStore(new CustomStore({
            loadMode: 'raw',
            key: 'id',
            load: async (o) => {
                if (!token) return [];
                return await GetServiceItemsForTeamAsync(evalUid, evalVersion, token)
                    .then(d => {
                        if (d.ok) {
                            return d.data!;
                        } else {
                            notify(`There was an error: ${d.error?.message}`, "error");
                            return [];
                        }
                    });
            },
            insert: async (v) => {
                if (!token || !v.name) return [];
                return await AddServiceItemAsync({ id: 0, name: v.name, evaluationUid: evalUid, evaluationVersion: evalVersion }, token)
                    .then(d => {
                        if (d.ok) {
                            return d.data!;
                        } else {
                            notify(`There was an error: ${d.error?.message}`, "error");
                            return [];
                        }
                    });
            },
        }));
    }, [token, evalUid, evalVersion]);

    return (
        <Popup visible={visible} onVisibleChange={onVisibilityChange}>
            <div className="overflow-auto h-100">
                <DataGrid
                    id='gridContainer'
                    showBorders={true}
                    dataSource={token ? store : undefined}
                    pager={{ allowedPageSizes: [10, 20, 50], visible: true, displayMode: "adaptive", showPageSizeSelector: true }}
                    editing={{ allowAdding: true, allowUpdating: false, mode: "batch" }}
                    filterRow={{ visible: true }}
                    repaintChangesOnly={true}
                >
                    <Column dataField="name" caption="Service Item Name" />
                </DataGrid>
            </div>
        </Popup>
    );
};